<template>
	<el-dialog title="货品关联" :visible.sync="config.dialogFormVisible">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="dataInitialize" :configurable="false" :queryParam="queryParam">
			<div slot="reserve">
				<div class="filter-box select-stock">
					<el-row>
						<el-col :span="3" class="form-title-stock">已选用户：</el-col>
						<el-col :span="21">
							<el-tag :key="tagIndex" v-for="(tag,tagIndex) in dynamicTags" closable
								:disable-transitions="false" @close="handleClose(tagIndex)">
								{{tag.name}}
							</el-tag>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="3" class="form-title-stock">请选择用户账户：</el-col>
						<el-col :span="21">
							<el-autocomplete v-model="state" :fetch-suggestions="querySearchAsync" placeholder="请输入内容"
								@select="handleSelect" ref="input" clearable >
								<template slot-scope="{ item  }" >
									<span>{{ item.name }}</span>
								</template> 
							</el-autocomplete>
						</el-col>
					</el-row>
				</div>
			</div>
			<el-table slot="elList" ref="warehouseListTable" :data="dataSource.Result" border
				@selection-change="handleSelectionChange" highlight-current-row>
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams" :key="index"
					:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
					:fixed="index==0&&(!config||!config.isDetailDisplay)"
					:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
					v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<4))">
					<template slot-scope="scope">
						<span> {{ scope.row[col.FieldName] }}</span>
					</template>
				</el-table-column>
			</el-table>
		</fixed-list>
		<div slot="footer" class="dialog-footer">
			<el-button @click="config.dialogFormVisible = false">取 消</el-button>
			<el-button type="primary" @click="sumbitStockRelation">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		mounted() {
			//this.restaurants = this.loadAll();
		},
		data() {
			return {
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					params: {}
				},
				userList: [],
				selectUserList: [],
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0,
				},
				dynamicTags: [],
				restaurants: [],
				state: "",
				foucusName: ""
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
				dialogFormVisible: false
			},
			selectCondition: {}
		},
		methods: {
			getData: function() {
				this.dataInitialize(); //加载表格数据
				this.getUserList(); //加载用户
			},
			onDataSourceChange(ds) {
				var _this = this;
				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			handleCurrentChange: function(val) {
				this.$emit("onSelectedRow", val);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			dataInitialize: function() {
				var _this = this;
				this.$ajax.query("omsapi/stock/stockrelation/searchstocksubinfolist", "post", _this.queryParam, (
					result) => {
					_this.dataSource = result;
				});
			},
			getUserList: function() {
				var _this = this;
				this.$ajax.query("omsapi/stock/stockrelation/getuserlist", "get", {}, (result) => {
					_this.userList = result.Result;
					if (_this.userList != null && _this.userList.length > 0) {
						_this.userList.forEach(item => {
							_this.restaurants.push({
								code: item.value,
								name: item.label

							});

						});
					}

				});
			},
			sumbitStockRelation: function() {
				var _this = this;
				if (_this.dynamicTags == null || _this.dynamicTags.length <= 0) {
					_this.$alert('请选择要操作的用户！', '商品用户关联信息提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				};
				if (_this.multipleSelection == null || _this.multipleSelection.length <= 0) {
					_this.$alert('请选择要操作的库存商品！', '商品用户关联信息提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				};
				var stockIDs = _this.Utils.selectionsToArr(_this.multipleSelection, "StockID");
				var codes= _this.Utils.selectionsToArr(_this.dynamicTags, "code");
				var parms = {
					UserCodes: codes,
					StockIDs: stockIDs
				};

				_this.$ajax.send("omsapi/stock/stockrelation/userrelationstock", "post", parms, (result) => {
					_this.$alert("关联成功！", '商品用户关联信息提示', {
						confirmButtonText: '确定',
						callback: action => {
							_this.multipleSelection = [];
							_this.selectUserList = [];
							_this.Event.$emit("stockRelationList");
						}
					});


				}, err => {
					_this.$alert(err, '商品用户关联信息提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
				});
			},
			handleClose(tagIndex) {
				this.dynamicTags.splice(tagIndex, 1);
			},
			querySearchAsync(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(f => f.name.indexOf(queryString) > -1) : restaurants;

				// clearTimeout(this.timeout);
				// this.timeout = setTimeout(() => {
				// 	cb(results);
				// 	this.$refs.test[0].$refs.input.focus();
				// }, 1000 * Math.random());

				cb(results);
				if (results != null && results.length > 0) {
					this.foucusName = results[0].name;

				}

				//this.$refs.input.focus();

			},
			handleSelect(item) {
				if (this.dynamicTags == null || this.dynamicTags.length <= 0) {
					this.dynamicTags = [];
				}
				if (this.dynamicTags.findIndex(f => f.code == item.code) > -1) {
					this.$alert('该用户已选中过！', '商品用户选择提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				this.state = item.name;
				this.dynamicTags.push(item);
			},
		},

	}
</script>
<style>
	.form-title-stock {
		line-height: 24px;
	}

	.select-stock .el-select {
		width: 90%;
	}

	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}

	.select-stock .el-autocomplete {
		width: 300px;

	}
</style>
